<template>
    <div class="our-work">
        <h1 v-if="$route.name === 'Projects'" v-html="$t('ourWork.heading')"></h1>

        <h2 v-else v-html="$t('ourWork.heading')"></h2>

        <ul class="list">
            <img
                v-if="!device.includes('mobile') && device !== 'desktop-lower'"
                class="list__upper-dots"
                src="/img/ourwork/upper-dots.png"
                alt=""
            >

            <li
                v-for="item in sortedItems"
                :key="item.title"
                class="list__item"
            >
                <div class="our-work__image">
                    <img
                        :src="`/img/ourwork/${item.img}.webp`"
                        :alt="item.alt"
                    >
                </div>

                <h3>{{ item.title }}</h3>

                <p v-html="item.description"></p>
            </li>

            <img
                v-if="!device.includes('mobile') && device !== 'desktop-lower' && $route.name === 'Projects'"
                class="list__down-dots"
                src="/img/ourwork/down-dots.png"
                alt=""
            >
        </ul>

        <router-link v-if="$route.name === 'Home'" to="/projects" class="view-all">
            {{ $t('ourWork.goToButton') }}
        </router-link>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'OurWork',
    computed: {
        ...mapState(['device']),
        sortedItems() {
            if (this.$route.name === 'Home') {
                if (this.device.includes('mobile')) return this.items.slice(0, 3)

                if (this.device.includes('desktop')) return this.items.slice(0, 2)
            }

            return this.items
        },
        items() {
            return [
                {
                    img: 'unitrooper',
                    title: this.$t('ourWork.projects.unitrooper.heading'),
                    description: this.$t('ourWork.projects.unitrooper.description'),
                    alt: 'Unitrooper',
                },
                {
                    img: 'portfolio-site',
                    title: this.$t('ourWork.projects.portfolioWebsite.heading'),
                    description: this.$t('ourWork.projects.portfolioWebsite.description'),
                    alt: 'Portfolio website',
                },
                {
                    img: 'cryptonight',
                    title: this.$t('ourWork.projects.cryptonight.heading'),
                    description: this.$t('ourWork.projects.cryptonight.description'),
                    alt: 'Cryptonight',
                },
                {
                    img: 'monsterjumps',
                    title: this.$t('ourWork.projects.webshop.heading'),
                    description: this.$t('ourWork.projects.webshop.description'),
                    alt: 'Webshop app',
                },
                {
                    img: 'backoffice',
                    title: this.$t('ourWork.projects.adminPanel.heading'),
                    description: this.$t('ourWork.projects.adminPanel.description'),
                    alt: 'Admin Panel',
                },
                {
                    img: 'food-app',
                    title: this.$t('ourWork.projects.foodDelivery.heading'),
                    description: this.$t('ourWork.projects.foodDelivery.description'),
                    alt: 'Food Delivery app',
                },
            ]
        },
    },
}
</script>

<style lang="scss">
.our-work {
    min-height: 140px;
    margin: 0 -18px 0;
    padding: 48px 18px;
    color: white;
    background-color: #616062;
    font-size: 14px;
    font-weight: 300;

    @media (min-width: 768px) {
        padding: 48px 24px;
    }

    @media (min-width: 1024px) {
        padding: 48px;
    }

    @media (min-width: 1300px) {
        padding: 66px;
    }

    h1 {
        padding-top: 12px;
        color: white;
        font-size: 30px;
        font-weight: 500;

        @media (min-width: 1024px) {
            margin-bottom: 36px;
            font-size: 40px;
        }
    }

    h3 {
        margin: 6px 0;
        font-size: 18px;
        font-weight: 700;

        @media (min-width: 768px) {
            font-size: 20px;
        }
    }

    a {
        color: #c2ffe0;
    }

    img {
        width: 100%;
    }

    p {
        margin: 0;
        font-size: 16px;

        @media (min-width: 768px) {
            font-size: 17px;
        }
    }

    &__image {
        margin-bottom: 18px;
        border-radius: 10px;
        background-color: #979797;

        @media (min-width: 768px) {
            height: 250px;
        }

        @media (min-width: 1300px) {
            height: 400px;
        }

        img {
            @media (min-width: 768px) {
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .list {
        @media (min-width: 768px) {
            display: flex;
            margin-bottom: 24px;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @media (min-width: 1300px) {
            position: relative;
            justify-content: space-around;
        }

        &__item {
            position: relative;
            margin-bottom: 36px;
            border-radius: 10px;

            @media (min-width: 768px) {
                width: 48%;
            }

            @media (min-width: 1300px) {
                width: 40%;
            }
        }

        &__upper-dots {
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
