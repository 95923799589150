<template>
    <div class="projects">
        <our-work />
    </div>
</template>

<script>
import OurWork from '@/components/OurWork'

export default {
    name: 'Projects',
    components: {
        OurWork,
    },
}
</script>

<style lang="scss">
.projects {
    .our-work {
        @media (min-width: 768px) {
            padding: 60px 24px 48px;
        }

        @media (min-width: 1024px) {
            padding: 78px 48px 48px;
        }

        @media (min-width: 1300px) {
            padding: 132px 66px 66px;
        }

        .list {
            &__upper-dots {
                top: 0;
            }

            &__down-dots {
                position: absolute;
                bottom: -170px;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
</style>
